import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "helpful-apple-links"
    }}>{`Helpful Apple Links`}</h1>
    <hr></hr>
    <p>{`iPhone / iPad:`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-gb/guide/iphone/iph60ba71065/ios"
      }}>{`Adjust the screen brightness and color on iPhone`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-gb/guide/iphone/iphe3fa5df43/ios"
      }}>{`iPhone models compatible with iOS 17`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-gb/guide/ipad/ipad213a25b2/ipados"
      }}>{`iPad models compatible with iOS 17`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-gb/guide/iphone/iph3c076905a/17.0/ios/17.0"
      }}>{`Make text more legible on iPhone`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-us/HT204204"
      }}>{`Update your iPhone or iPad`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-us/HT202180"
      }}>{`How to manually update apps on your Apple device`}</a></p>
    <p>{`Apple Developer Account:`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://developer.apple.com/help/app-store-connect/manage-agreements/sign-and-update-agreements/"
      }}>{`Sign and update agreements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://developer.apple.com/help/app-store-connect/manage-agreements/view-agreements-status"
      }}>{`View agreements status`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      